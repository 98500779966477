<template>
    <GDialog
        v-model="uiStore.dialog.isOpen"
        :border-radius="false"
        content-class="max-w-[500px] base-m"
    >
        <div class="base-p">
            <slot>
                <p v-html="uiStore.dialog.question" class="[&>a]:underline"></p>
                <br>
                <p v-html="uiStore.dialog.body" class="[&>a]:underline"></p>
            </slot>
            <div class="flex justify-end w-full base-space-x base-mt">
                <button
                    class="button link-noline"
                    @click.prevent="cancel"
                >
                    {{ uiStore.cancelLabel }}
                </button>

                <button
                    class="button button-primary"
                    @click.prevent="confirm"
                >
                    {{ uiStore.confirmLabel }}
                </button>
            </div>
        </div>
    </GDialog>
</template>

<script setup>
import { GDialog } from 'gitart-vue-dialog';
import { useUIStore } from '~/store/ui';
import 'gitart-vue-dialog/dist/style.css';

const uiStore = useUIStore();

const cancel = () => {
    uiStore.closeDialog();
};
const confirm = () => {
    uiStore.dialog.confirmCallback();
    uiStore.closeDialog();
};

</script>
