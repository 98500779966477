import { createRouter, createWebHistory } from 'vue-router';
import auth from '~/middleware/auth';
import guest from '~/middleware/guest';
import notVerified from '~/middleware/notVerified';
import middlewarePipeline from '~/router/middlewarePipeline';
import { sendPageViewToAnalytics } from '~/services/analytics';

const Register = () => import('~/views/auth/Register.vue');
const VerifyPhoneNumber = () => import('~/views/auth/VerifyPhoneNumber.vue');
const Login = () => import('~/views/auth/Login.vue');
const ForgotPassword = () => import('~/views/auth/ForgotPassword.vue');
const ResetPassword = () => import('~/views/auth/ResetPassword.vue');
const Dashboard = () => import('~/views/profile/Dashboard.vue');
const CreateEvent = () => import('~/views/events/CreateEvent.vue');
const EditEvent = () => import('~/views/events/EditEvent.vue');
const Configurator = () => import('~/views/bookingtool/Configurator.vue');
const Confirmation = () => import('~/views/bookingtool/Confirmation.vue');
// const Checkin = () => import('~/views/checkin/Checkin.vue');

const routes = [
    {
        path: '/app',
        name: 'home',
        redirect: to => {
            return { name: 'profile-dashboard' };
        },
    },
    {
        path: '/app/registrieren',
        name: 'register',
        meta: {
            middleware: [guest],
            title: 'Registrieren',
        },
        component: Register,
    },
    {
        path: '/app/mobilnummer-verfizieren',
        name: 'verify-phone-number',
        meta: {
            middleware: [notVerified],
            title: 'Registrieren',
        },
        component: VerifyPhoneNumber,
    },
    {
        path: '/app/login',
        name: 'login',
        meta: {
            middleware: [guest],
            title: 'Login',
        },
        component: Login,
    },
    {
        path: '/app/passwort-vergessen',
        name: 'forgot-password',
        meta: {
            middleware: [guest],
            title: 'Passwort vergessen',
        },
        component: ForgotPassword,
    },
    {
        path: '/app/passwort-reset/:token',
        name: 'reset-password',
        meta: {
            middleware: [guest],
            title: 'Passwort zurücksetzen',
        },
        component: ResetPassword,
    },
    {
        path: '/app/profil',
        name: 'profile-dashboard',
        meta: {
            middleware: [auth],
            title: 'Profil',
        },
        component: Dashboard,
    },
    {
        path: '/app/events/erstellen',
        name: 'events-create-event',
        meta: {
            middleware: [auth],
            title: 'Event erstellen',
            section: 'Events',
        },
        component: CreateEvent,
    },
    {
        path: '/app/events/bearbeiten/:eventId',
        name: 'events-edit-event',
        meta: {
            middleware: [auth],
            title: 'Event bearbeiten',
            section: 'Events',
        },
        component: EditEvent,
    },
    {
        path: '/app/buchungen/konfigurator',
        name: 'bookingtool-configurator',
        meta: {
            middleware: [auth],
            title: 'Raumbuchung',
            section: 'Raumbuchung',
        },
        component: Configurator,
    },
    {
        path: '/app/buchungen/bestaetigung',
        name: 'bookingtool-confirmation',
        meta: {
            middleware: [auth],
            title: 'Buchung bestätigt',
            section: 'Raumbuchung',
        },
        component: Confirmation,
    },
    // {
    //     path: '/app/buchungen/einchecken',
    //     name: 'checkin',
    //     meta: {
    //         middleware: [auth],
    //         title: 'Einchecken',
    //         section: 'Raumbuchung',
    //     },
    //     component: Checkin,
    // },
    {
        path: '/:catchAll(.*)',
        name: 'not-found',
        redirect: to => {
            window.location.href = '/404';
            return false;
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    const { middleware } = to.meta;
    const context = { to, from, next };

    if (!middleware) {
        return next();
    }

    middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    });
});

router.afterEach((to, from, failure) => {
    if (!failure) {
        document.title = `${window.env.PRODUCT_NAME} | ${to.meta.title}`;
        sendPageViewToAnalytics(to, from);
    }
});

export default router;
