import { defineStore } from 'pinia';

export const useAnalyticsStore = defineStore('analytics', {
    state: () => {
        return {
            consentGiven: false,
            virtualPage: false,
        };
    },
});
