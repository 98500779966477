import { listenForInteraction, sendFormStepToAnalytics } from '~/services/analytics.js';

export default {

    init() {
        document.querySelectorAll('form.fui-form').forEach((el) => {
            let formConfig = {};
            try {
                formConfig = JSON.parse(el.dataset.fuiForm);
            } catch (e) {
                console.error(e);
            }
            if(formConfig.formHandle) {
                this.trackFirstInteraction(el, formConfig);
                this.trackSubmit(el, formConfig);
            }
        });
    },

    trackFirstInteraction(el, formConfig) {
        listenForInteraction(el, () => {
            sendFormStepToAnalytics({ formName: formConfig.formHandle });
        });
    },

    trackSubmit(el, formConfig) {
        el.addEventListener('onAfterFormieSubmit', () => {
            sendFormStepToAnalytics({ formName: formConfig.formHandle, type: 'end', step: 2 });
        });
    },

};
