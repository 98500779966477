export default {
    general: {
        buttonCancel: 'Zurück',
        buttonConfirm: 'OK',
    },
    auth: {
        headingNewUser: 'Neu hier?',
        introTextNewUser: 'Registriere dich, um einen Arbeitsplatz oder einen unserer Spaces zu buchen und auf dem Laufenden zu bleiben.',
        headingRegister: 'Neu hier?',
        introTextRegister: 'Registriere dich, um einen Arbeitsplatz oder einen unserer Spaces zu buchen und auf dem Laufenden zu bleiben.',
        buttonRegister: 'Registrieren',
        headingLogin: 'Login',
        introTextLogin: 'Du hast dich am 23. Mai 2022 oder zu einem späteren Zeitpunkt bereits neu registriert.',
        oldPageHint: 'Login der alten Website sind nicht mehr gültig. Bitte registriere dich neu.',
        buttonLogout: 'Abmelden',
        labelLoginEmail: 'E-Mail-Adresse',
        labelLoginPassword: 'Passwort',
        buttonLogin: 'Einloggen',
        linkForgotPassword: 'Passwort vergessen',
        registerTerms: 'Ich akzeptiere die {0} und die {1}.',
        acceptNewsletter: 'Ich möchte über kostenlose Events, Workshops und allgemeine Neuigkeiten zum Büro Züri, Freiraum und Büro Züri Innovationspark informiert werden.',
        terms: 'Nutzungsbedingungen',
        policy: 'Datenschutzbestimmungen',
        headingVerify: 'Bestätigung',
        introTextVerify: 'Bitte bestätige den Code, den wir an deine Mobilnummer gesendet haben.',
        resendVerificationCode: 'Code erneut senden.',
        buttonVerify: 'Bestätigen',
        headingForgotPassword: 'Du hast dein Passwort vergessen?',
        textForgotPassword: 'Hier kannst du ein neues einrichten:',
        buttonForgotPassword: 'Passwort zurücksetzen',
        headingPasswordResetSent: 'Neues Passwort - Bestätigung ausstehend',
        textPasswordResetSent: 'Wir haben dir eine E-Mail mit einem Link zum setzen des neuen Passworts geschickt. Bitte prüfe deine Mails (auch den Spam-Ordner). \n\n'
            + 'Du hast keine Mail erhalten? Dann klicke bitte unten stehenden Link.',
        buttonResendForgotPasswordLink: 'E-Mail erneut senden.',
        headingResetPassword: 'Neues Passwort setzen',
        registrationDisabled: 'Die Registrierung ist vorübergehend nicht möglich. Bitte versuche es in {time} nochmals.',
    },
    bookingtool: {
        introTextNew: 'Hallo {name}, hier siehst du unser Büro Züri Angebot.',
        introTextEdit: 'Buchung #{bookingNr} anpassen:',
        headingDateSelector: 'Verfügbare Daten',
        headingSpaceSelector: 'Büro Züri Angebot',
        headingTimeSelector: 'Gewünschter Zeitraum {durationRestriction}',
        submitSingle: 'Buchung bestätigen',
        submitRebooking: 'Buchung umbuchen',
        submitWorkshoproom: 'Buchungsanfrage absenden',
        hint: 'Nimm {contact} mit uns auf, falls du Fragen hast.',
        contact: 'Kontakt',
        buttonSaveBooking: 'Buchen',
        buttonSaveRebooking: 'Umbuchung bestätigen',
        questionConfirmRebooking: 'Möchtest du die Umbuchung tätigen?',
        questionConfirmBooking: 'Möchtest du diesen Zeitraum buchen?',
        questionConfirmBookingWarning: 'Bitte beachte: Auch abgesagte Buchungen werden gezählt. <a href="https://buero-zueri.ch/stories/einfuehrung-eines-kontingents-fuer-buchungen" target="_blank">Mehr dazu</a>.',
        questionConfirmWorkshopBooking: 'Möchtest du diesen Zeitraum anfragen?',
        buttonSaveWorkshopBooking: 'Anfragen',
        messageHint: {
            line1: 'Was ist das Thema deines Workshops oder Events?',
            line2: 'Wie viele Personen nehmen teil?',
        },
    },
    profile: {
        introText: 'Hallo {name}, hier ist dein Profil',
        buttonLogout: 'Logout',
        buttonCheckin: 'Check-In',
        buttonCheckout: 'Check-Out',
        bookingsHeading: 'Meine Buchungen',
        bookingsHeadingContingent: 'Verfügbares Kontingent: {contingent}',
        bookingsNewButton: 'Neue Buchung',
        eventsHeading: 'Meine Events',
        eventsLead: 'Hier hast du die Möglichkeit, eigene Events zu erfassen. Die Anmeldung erfolgt über ein beliebiges Registrationsportal oder einen persönlichen Kontakt (z.B. E-Mail). Die Teilnahme muss kostenfrei sein.',
        eventsNewButton: 'Neuer Event',
        profileDataHeading: 'Mein Profil',
        questionCancelBooking: 'Möchtest du die Buchung stornieren?',
        buttonCancelBooking: 'Stornieren',
        buttonCancel: 'Abbrechen',
        questionCancelEvent: 'Möchtest du den Event stornieren?',
        buttonCancelEvent: 'Stornieren',
        buttonProfileDataEdit: 'Mein Profil bearbeiten',
        headingChangePassword: 'Passwort',
        headingDangerZone: 'Profil löschen',
        questionConfirmDeleteAccount: 'Möchtest du dieses Profil und alle Buchungen unwiderruflich löschen?',
        buttonConfirmDeleteAccount: 'Profil löschen',
    },
    formLabels: {
        title: 'Anrede',
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'E-Mail',
        message: 'Details zu deinem Workshop oder Event',
        topic: 'Thema',
        maxParticipants: 'Anzahl Teilnehmer',
        phoneNumber: 'Mobilnummer (zwingend für Registrierung)',
        password: 'Passwort',
        passwordCurrent: 'Aktuelles Passwort',
        passwordNew: 'Neues Passwort',
        passwordConfirm: 'Passwort bestätigen',
        buttonChangeProfile: 'Profil aktualisieren',
        buttonChangePassword: 'Passwort aktualisieren',
        buttonDeleteAccount: 'Profil löschen',
    },
    flashMessages: {
        profileUpdated: 'Profil wurde aktualisiert.',
        passwordUpdated: 'Passwort wurde aktualisiert.',
        bookingCancelled: 'Die Buchung wurde storniert.',
        bookingCheckout: 'Die Buchung wurde ausgecheckt.',
        eventCancelled: 'Der Event wurde storniert.',
        passwordResetSent: 'Ein Email für die Zurücksetzung des Passwortes wurde versandt.',
        phoneVerified: 'Mobilnummer wurde bestätigt.',
        phoneVerificationCodeResent: 'Bestätigungscode wurde erneut verschickt.',
        accountDeleted: 'Dein Profil wurde erfolgreich gelöscht.',
    },
    errors: {
        general: 'Es ist ein Fehler aufgetreten.',
        formValidation: 'Deine Angaben sind nicht korrekt.',
        login: 'Die Zugangsdaten sind nicht korrekt.',
        bookingtool: { general: 'Bei der Buchung ist ein Fehler aufgetreten.' },
    },
    events: {
        formLabels: {
            name: 'Event-Titel',
            host: 'Host (Event-Veranstalter)',
            address: 'Adresse',
            location: 'Location Name (z.B. Freiraum)',
            maxParticipants: 'Anzahl Teilnehmer',
            link: 'Link Anmeldung (optional)',
            eventType: 'Veranstaltungstyp',
            eventForm: 'Veranstaltungsart',
            eventForms: {
                'vor-ort': 'Vor Ort',
                'online': 'Online',
            },
            dateAndTime: 'Datum und Uhrzeit',
            date: 'Datum',
            startTime: 'Zeit von',
            endTime: 'Zeit bis',
            description: 'Event-Beschrieb',
            acceptTerms: 'Ich habe die {0} gelesen und erkläre mich damit einverstanden.',
            terms: 'Nutzungsbedingungen',
        },
        saveButton: 'Event speichern',
        cancelButton: 'Abbrechen',
        confirmLeaveQuestion: 'Möchtest du die Seite wirklich verlassen? Du hast ungespeicherte Änderungen.',
        createEvent: {
            heading: 'Event erfassen',
            intro: 'Hier hast du die Möglichkeit, eigene Events zu erfassen.\nDie Anmeldung erfolgt über ein beliebiges Registrationsportal oder einen persönlichen Kontakt (z.B. E-Mail)',
            confirmSaveQuestion: 'Möchtest du diesen Event erfassen?',
            confirmSaveButton: 'Speichern',
            confirmation: 'Dein Event wurde erfasst.',
        },
        editEvent: {
            heading: 'Event bearbeiten',
            intro: 'Hier hast du die Möglichkeit, eigene Events zu erfassen.\nDie Anmeldung erfolgt über ein beliebiges Registrationsportal oder einen persönlichen Kontakt (z.B. E-Mail)',
            confirmSaveQuestion: 'Möchtest du diesen Event aktualisieren?\nDein Event wird vom Büro Züri-Team geprüft. Du wirst per E-Mail benachrichtigt, sobald dieser freigegeben wurde.',
            confirmSaveButton: 'Speichern',
            confirmation: 'Event wurde gespeichert.',
        },
    },
    checkin: {
        noBookingsHeading: 'Keine Buchung zum Einchecken gefunden.',
        checkinSuccessHeading: 'Du bist jetzt eingecheckt!',
        checkinMissedHeading: 'Tut uns leid, die Check-In-Zeit ist vorbei.',
        checkinUpcomingHeading: 'Der Check-in ist noch nicht möglich, er beginnt in {minutes}m{seconds}s.',
        toProfileButton: 'Zu meinem Profil',
    },
    confirmation: {
        titleMale: 'Lieber',
        titleFemale: 'Liebe',
        titleDivers: 'Liebe*',
        line1Single: 'deine Reservation war erfolgreich',
        line1Workshop: 'Vielen Dank. Deine Anfrage wird bearbeitet',
        until: 'bis',
        buttonICal: 'Termin in iCal speichern',
        buttonReservations: 'Zu meinen Reservationen',
        icsDescription: 'Einchecken kannst du vor Ort ab Buchungsbeginn bis {checkinTo}min nach Buchungsbeginn',
    },
};
