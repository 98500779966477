import { createI18n } from 'vue-i18n';
import de from '~/lang/de';

// Load all messages stored in the json file in the lang directory
const i18n = createI18n({
    globalInjection: true,
    legacy: false,
    locale: 'de',
    messages: { de },
});

// On the app page, additional messages may be present in the window object
// They are managed as Craft CMS i18n messages
if(window.messages) {
    i18n.global.mergeLocaleMessage('de', window.messages);
}

window.$t = (key, params = null) => i18n.global.t(key, params);

export default i18n;
