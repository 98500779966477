import Flickity from 'flickity';

const SEL_FLICKITY = '[data-flickity-slider]';
const SEL_FLICKITY_ITEM = '[data-flickity-cell]';

export default {

    sliders: [],

    init() {
        this.loadFlickity();

        // Initialize again after swapping DOM via Sprig
        // document.body.addEventListener('htmx:afterSwap', (event) => {

        // });
    },

    loadFlickity() {
        const flickityElements = document.querySelectorAll(SEL_FLICKITY);

        if (!flickityElements.length) return;

        flickityElements.forEach((flickityEl => {
            const setGallerySize = flickityEl.hasAttribute('data-flickity-auto-height');
            const watchCSS = flickityEl.hasAttribute('data-flickity-watchcss');

            this.sliders.push(new Flickity(flickityEl, {
                cellSelector: SEL_FLICKITY_ITEM,
                wrapAround: true,
                setGallerySize,
                prevNextButtons: false,
                pageDots: false,
                cellAlign: 'left',
                watchCSS,
            }));
        }));
    },
};
