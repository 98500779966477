import { useAuthStore } from '~/store/auth';

export default async function auth({ to, next }) {
    const loginQuery = { name: 'login', query: { redirect: to.name } };
    const verifyQuery = { name: 'verify-phone-number', query: { redirect: to.name } };
    const authStore = useAuthStore();

    // Redirect to the login screen if not authenticated
    if (!await authStore.getUser()) {
        return next(loginQuery);
    }

    // If a user is authenticated but hasn't verified their
    // phone number yet, redirect to verification screen
    if (!authStore.isVerified && to.name !== verifyQuery.name) {
        return next(verifyQuery);
    }

    return next();
}
