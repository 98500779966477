import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';

/**
 * Extract validation messages from API response
 */
const getAPIError = (error) => {
    // Show error in console in debug mode
    if (window.env.APP_DEBUG) {
        console.error('API Error', error);
    }

    if (error.response) {
        // map field validation errors to camelcased properties
        if (error?.response?.data?.errors) {
            return mapKeys(error.response.data.errors, (value, key) => {
                return camelCase(key);
            });
        }

        // If there are no field errors (data.errors), then return the normal error message
        // under the key "general" for general server errors
        if (error?.response?.data?.message) {
            return { general: error?.response?.data?.message };
        }
    }

    // return general error message, if nothing from API can be found
    return { general: error.message };
};

/**
 * handles errors from API requests.
 * Stores the errors to a given errorBag ref.
 * If no errorbag is given, throw
 */
export const handleAPIErrors = (error, errorBag = null) => {
    if (errorBag) {
        errorBag.value = { ...errorBag.value, ...getAPIError(error) };
    } else {
        throw error;
    }
};
