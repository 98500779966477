import { defineStore } from 'pinia';
import { getConfig } from '~/services/bookingtool';

export const useConfigStore = defineStore('config', {
    state: () => {
        return {
            checkinFrom: null,
            checkinTo: null,
            notificationCheckinFrom: null,
            notificationCheckinTo: null,
        };
    },

    actions: {
        async getConfig() {
            this.$patch(await getConfig());
        },
    },

});
