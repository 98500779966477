import axios from '~/lib/axios';
import { handleAPIErrors } from '~/utils/errors';

/**
 * Get the user representation
 */
export const user = () => axios
    .get('/api/v1/users/auth')
    .then(res => res.data.data);

/**
 * Load the CSRF token
 */
export const getCSRF = () => axios.get('/sanctum/csrf-cookie');

/**
 * Register a new user
 */
export const register = async ({ errors = null, payload = {}, loadingState = null } = {}) => {
    if (errors) errors.value = [];
    if (loadingState) loadingState.value = true;

    await getCSRF();

    try {
        await axios.post('/register', {
            title: payload.title,
            first_name: payload.firstName,
            last_name: payload.lastName,
            email: payload.email,
            phone_number: payload.phoneNumber,
            password: payload.password,
            password_confirmation: payload.passwordConfirmation,
            terms: payload.terms,
            accept_newsletter: payload.acceptNewsletter,
        }).finally(() => {
            if (loadingState) loadingState.value = false;
        });

        return true;
    } catch (error) {
        handleAPIErrors(error, errors);
        return null;
    }
};

/**
 *
 */
export const resendVerificationCode = async ({ errors = null, revalidate = null } = {}) => {
    if (errors) errors.value = [];

    await getCSRF();

    try {
        await axios.post('/api/v1/users/send-phone-verification');

        // DEBUG: Reload user data (for debugging purposes only)
        revalidate();

        return true;
    } catch (error) {
        handleAPIErrors(error, errors);
        return null;
    }
};

/**
 * Verify the users phone number
 */
export const verifyPhoneNumber = async ({ errors = null, loadingState = null, payload = {}, revalidate = null } = {}) => {
    if (errors) errors.value = [];
    if (loadingState) loadingState.value = true;

    await getCSRF();

    try {
        await axios.post('/api/v1/users/verify-phone', { code: payload.code }).finally(() => {
            if (loadingState) loadingState.value = false;
        });

        // Reload user data
        await revalidate();
        return true;
    } catch (error) {
        handleAPIErrors(error, errors);
        return null;
    }
};

/**
 * Login user
 */
export const login = async ({ errors = null, payload = {} } = {}) => {
    if (errors) errors.value = [];

    await getCSRF();

    try {
        await axios.post('/login', {
            email: payload.email,
            password: payload.password,
            remember: payload.remember,
        });

        return true;
    } catch (error) {
        handleAPIErrors(error, errors);
        return null;
    }
};

/**
 * Request password reset email
 */
export const forgotPassword = async ({ errors = null, payload = {} } = {}) => {

    await getCSRF();

    if (errors) errors.value = [];
    try {
        await axios.post('/forgot-password', { email: payload.email });
        return true;
    } catch (error) {
        handleAPIErrors(error, errors);
        return null;
    }
};

/**
 * Reset password
 */
export const resetPassword = async ({ errors = null, payload = {} } = {}) => {
    if (errors) errors.value = [];

    await getCSRF();

    try {
        await axios.post('/reset-password', {
            email: payload.email,
            password: payload.password,
            password_confirmation: payload.passwordConfirmation,
            token: payload.token,
        });

        return true;
    } catch (error) {
        handleAPIErrors(error, errors);
        return null;
    }
};

/**
 * Update password
 */
export const updatePassword = async ({ errors = null, payload = {} } = {}) => {
    if (errors) errors.value = [];

    await getCSRF();

    try {
        await axios.put('/user/password', {
            current_password: payload.currentPassword,
            password: payload.password,
            password_confirmation: payload.passwordConfirmation,
        });

        return true;
    } catch (error) {
        handleAPIErrors(error, errors);
        return null;
    }
};

/**
 * Update user profile
 */
export const updateProfileData = async ({ errors = null, payload = {}, loadingState = null } = {}) => {
    if (errors) errors.value = [];
    if (loadingState) loadingState.value = true;

    await getCSRF();

    try {
        await axios.put('/user/profile-information', {
            title: payload.title,
            first_name: payload.firstName,
            last_name: payload.lastName,
            email: payload.email,
        }).finally(() => {
            if (loadingState) loadingState.value = false;
        });

        return true;
    } catch (error) {
        handleAPIErrors(error, errors);
        return null;
    }
};

/**
 * Logout user
 */
export const logout = async () => {
    await getCSRF();
    return axios.post('/logout');
};

/**
 * Delete account
 */
export const deleteAccount = async () => {
    await getCSRF();
    return axios.delete('/api/v1/users/delete');
};
