import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

const SEL_MASONRY_GRID = '[data-masonry-grid]';
const SEL_MASONRY_ITEM = '[data-masonry-item]';
const SEL_MASONRY_STAMP = '[data-masonry-stamp]';
const SEL_MASONRY_COLUMN_SIZER = '[data-masonry-column-sizer]';
const SEL_MASONRY_GUTTER_SIZER = '[data-masonry-gutter-sizer]';

export default {
    init() {
        this.loadMasonry();

        // Initialize Masonry Grid again after swapping DOM via Sprig
        document.body.addEventListener('htmx:afterSwap', (event) => {
            this.loadMasonry();
        });
    },

    loadMasonry() {
        const masonryEl = document.querySelector(SEL_MASONRY_GRID);
        if (!masonryEl) return;

        const masonry = new Masonry(masonryEl, {
            itemSelector: SEL_MASONRY_ITEM,
            columnWidth: SEL_MASONRY_COLUMN_SIZER,
            gutter: SEL_MASONRY_GUTTER_SIZER,
            percentPosition: true,
            transitionDuration: 0,
            horizontalOrder: true,
            stamp: SEL_MASONRY_STAMP,
        });

        imagesLoaded(masonryEl).on('progress', () => {
            masonry.layout();
        });
    },
};
