<template>
    <div v-if="uiStore.flashMessage" class="fixed z-30 p-4 text-white shadow-lg top-4 left-1/2 -translate-x-1/2 bg-primary">
        {{ uiStore.flashMessage }}
    </div>
</template>

<script setup>
import { useUIStore } from '~/store/ui';

const uiStore = useUIStore();
</script>
