<template>
    <div class="isolate items-start mb-16 w-full transition-[height] duration-500 ease-in-out base-grid" :style="containerStyle">
        <div class="z-30 col-start-1 col-end-9 row-start-1 lg:col-end-5 lg:mt-8 base-px">
            <h1 class="typo-heading-2xl">
                {{ heading }}
            </h1>

            <div class=" md:flex">
                <button
                    v-if="!isFormVisible && !hideBookingForm"
                    class="button button-primary mt-3 mr-3 "
                    @click="showForm"
                >
                    {{ buttonLabel }}
                </button>

                <a
                    v-if="externalLink2"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="button button-primary mt-3"
                    :href="externalLink2"
                >
                    {{ buttonLabel2 }}
                </a>
            </div>
        </div>

        <transition
            :css="false"
            @enter="enter"
            @leave="leave"
        >
            <div v-show="!isFormVisible" class="z-10 col-start-5 col-end-13 lg:row-start-1 lg:col-start-7 lg:col-end-13 base-pt lg:pt-0">
                <slot name="image" />
            </div>
        </transition>

        <transition
            :css="false"
            @enter="enter"
            @leave="leave"
        >
            <div v-show="isFormVisible" class="z-20 col-start-1 col-end-13 lg:row-start-1 lg:col-start-4 lg:col-end-13 base-pt lg:pt-0">
                <div class="relative flex justify-end pt-12 bg-secondary base-p">
                    <button class="absolute top-5 right-5" @click="hideForm">
                        <div class="w-4 h-4">
                            <IconClose />
                        </div>
                    </button>

                    <div class="w-full lg:w-10/12">
                        <slot name="form" />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import gsap from 'gsap';
import IconClose from '@svg/icons/icon-close.svg';

const props = defineProps({
    heading: {
        type: String,
        default: '',
    },
    hideBookingForm: {
        type: Boolean,
        default: false,
    },
    buttonLabel: {
        type: String,
        default: '',
    },
    externalLink: {
        type: String,
        default: '',
    },
    buttonLabel2: {
        type: String,
        default: '',
    },
    externalLink2: {
        type: String,
        default: '',
    },
});

const isFormVisible = ref(false);
const containerHeight = ref(0);

const containerStyle = computed(() => {
    return containerHeight.value ? { height: `${containerHeight.value}px` } : '';
});

const showForm = () => {
    if (props.externalLink) {
        window.open(props.externalLink);
    } else {
        isFormVisible.value = true;
    }
};

const hideForm = () => {
    isFormVisible.value = false;
};

const enter = async (el, done) => {
    containerHeight.value = el.clientHeight;
    gsap.fromTo(el, { opacity: 0 }, {
        opacity: 1,
        duration: 0.5,
        ease: 'power4.inOut',
        onComplete: () => {
            containerHeight.value = 0;
            done();
        },
    });
};

const leave = async (el, done) => {
    containerHeight.value = el.clientHeight;
    gsap.fromTo(el, { opacity: 1 }, {
        opacity: 0,
        duration: 0.5,
        ease: 'power4.inOut',
        onComplete: () => {
            containerHeight.value = 0;
            done();
        },
    });
};
</script>

<script>
export default {};
</script>
