import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import { useAuthStore } from '~/store/auth';

const axios = Axios.create({
    baseURL: window.env.API_URL, //process.env.API_URL,
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    withCredentials: true,
    withXSRFToken: true,
});

// Use axios-cache, but set cache duration to 0 as default
// Meaning you can use caching on specific requests if needed
setupCache(axios, { ttl: 0 });

axios.interceptors.response.use(
    response => response,
    error => {
        const authStore = useAuthStore();
        if (
            error.response
            && [401, 419].includes(error.response.status)
            && authStore.isLoggedIn
        ) {
            authStore.logout();
        }
        return Promise.reject(error);
    }
);

export default axios;
