<template>
    <VueCollapsiblePanel
        :id="id"
        :expanded="expanded"
        tabindex="0"
    >
        <template #title>
            {{ item.question }}
        </template>
        <template #content>
            <div>
                <div v-html="item.answer" />
            </div>
        </template>
    </VueCollapsiblePanel>
</template>

<script setup>
import { VueCollapsiblePanel } from '@dafcoe/vue-collapsible-panel';
import slugify from '@sindresorhus/slugify';
import '@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css';
import { computed, onMounted, ref } from 'vue';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
});

const expanded = ref(false);
const id = computed(() => slugify(props.item.question));

const hash = window.location.hash;
if (hash === `#${id.value}`) {
    expanded.value = true;
}

onMounted(() => {
    const hash = window.location.hash;
    if (hash) {
        const element = document.querySelector(hash);
        if (element) {
            element.scrollIntoView();
        }
    }
});
</script>
