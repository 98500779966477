import { defineStore } from 'pinia';

export const useUIStore = defineStore('ui', {
    state: () => {
        return {
            dialog: {
                isOpen: false,
                question: '',
                body: '',
                confirmLabel: '',
                cancelLabel: '',
                confirmCallback: null,
            },
            flashMessage: '',
            checkin: { showSuccess: false },
        };
    },

    actions: {
        openDialog({ question = '', body = '', confirmLabel = '', confirmCallback = null }) {
            this.dialog.isOpen = true;
            this.dialog.question = question;
            this.dialog.body = body;
            this.dialog.confirmLabel = confirmLabel;
            this.dialog.confirmCallback = confirmCallback;
        },

        closeDialog() {
            this.dialog.isOpen = false;
            this.dialog.question = '';
            this.dialog.confirmLabel = '';
            this.dialog.confirmCallback = null;
        },

        setFlashMessage(flashMessage, duration = 5) {
            this.flashMessage = flashMessage;
            if (duration) {
                setTimeout(() => {
                    this.flashMessage = '';
                }, duration * 1000);
            }
        },

        showCheckinSuccessModal() {
            this.checkin.showSuccess = true;
        },

        hideCheckinSuccessModal() {
            this.checkin.showSuccess = false;
        },
    },

    getters: {
        cancelLabel() {
            return this.dialog.cancelLabel || $t('general.buttonCancel');
        },

        confirmLabel() {
            return this.dialog.confirmLabel || $t('general.buttonConfirm');
        },
    },
});
